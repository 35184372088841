.heroSection_wrapper {
  width: 100vw;
  background-image: url(../../assets/images/background.png);
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow-x: hidden;
}

.header_text {
  font-size: clamp(30px, 4vw, 47px);
  color: #fff;
  font-weight: 600;
}
.header_text span {
  background: rgb(75, 134, 252);
  background: linear-gradient(
    142deg,
    rgba(75, 134, 252, 1) 30%,
    rgba(117, 242, 246, 1) 100%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.header_para_text {
  font-size: clamp(12px, 0.8vw, 14px);
  font-weight: 400;
}
.startbtn {
  background: var(--gradient);
  border: 2px solid transparent;
  border-radius: 50px;
  transition: 250ms ease-in-out;

}

.btnInside_wrap {
  padding: 10px;
  background: #000;
  transition: 250ms ease-in-out;
}
.btn_text {
  font-size: 20px;
  background: var(--gradient);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: 250ms ease-in-out;
}
.circle {
  width: 35px;
  height: 35px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../../assets//icons/arrowUp.png);
  background-size: 40%;
  background-position: center;
  background-color: rgb(75, 134, 252);
  background-repeat: no-repeat;
}

.startbtn:hover .btnInside_wrap {
  background: var(--gradient);
  color: #000 !important;
}
.startbtn:hover .btn_text {
  background: #000;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}


.analysisWrapper {
  width: 250px;
  height: 230px;
  background-color: red;
  border-radius: 20px;
  z-index: 1;
  background: hsla(0, 0%, 100%, 1);
  background: linear-gradient(
    135deg,
    hsla(0, 0%, 100%, 1) 0%,
    hsla(256, 10%, 22%, 1) 0%,
    hsla(255, 17%, 14%, 1) 26%,
    hsla(258, 25%, 10%, 1) 53%,
    hsla(258, 29%, 9%, 1) 67%
  );
}
.select_month {
  border: none;
  outline: none;
  background: none;
  font-size: 10px;
}
.select_month option {
  background-color: #000;
}
.hero_right_Column {
  max-width: 600px;
  width: 100%;
}
.chartData {
  font-size: 12px;
}
.chartData span {
  font-size: 9px;
  display: block;
}
.monthsList {
  list-style-type: none;
  display: flex;
  gap: 8px;
  font-size: 10px;
  color: #fff;
  text-transform: capitalize;
  align-items: center;
  width: fit-content;
  margin: 0 auto;
}
.monthsList li{
  cursor: pointer;
}

.active_month{
  padding: 5px 6px;
  background-color: #00d9f5;
  border-radius: 10px;
  color: #000;
}
.payMethods_wrap {
  position: relative;
  width: 260px;
  height: 140px;
  z-index: 1;
  background: hsla(0, 0%, 100%, 1);
  background: linear-gradient(
    135deg,
    hsla(0, 0%, 100%, 1) 0%,
    hsla(256, 10%, 22%, 1) 0%,
    hsla(255, 17%, 14%, 1) 26%,
    hsla(258, 25%, 10%, 1) 53%,
    hsla(258, 29%, 9%, 1) 67%
  );
  border-radius: 20px;
  top: -20px;
}
.pay_img {
  width: 48px;
  height: 48px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 55%;
  border-radius: 10px;
  background-color: #fff;
}
.cover {
  background-size: 90%;
}
.backgroundCircles {
  max-width: 380px;
  max-height: 380px;
  width: 20vw;
  height: 20vw;
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  left: 0;
}
.backgroundCircles1 {
  width: 70%;
  height: 70%;
  background-size: contain;
  background-repeat: no-repeat;
}
.robotHand {
  max-width: 590px;
  max-height: 392px;
  min-width: 400px;
  width: 40vw;
  height: 35vw;
  background-image: url(../../assets//images/robot_hand.png);
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
  bottom: -200px;
}
.our_paymentMethod_wrapper {
  margin-top: 130px;
}
.payments_row {
  background-color: rgba(2, 0, 13, 0.2);
}
.paymentMethods_icons {
  width: 75px;
  height: 60px;
  object-fit: contain;
}
.subscriptionTitle {
  font-size: clamp(37px, 4vw, 47px);
}
.subscriptionSection_wrap {
  background: hsla(208, 73%, 10%, 1);
  background: linear-gradient(
    360deg,
    hsla(208, 73%, 10%, 1) 0%,
    hsla(225, 89%, 4%, 1) 100%
  );
}
.leftBtn {
  padding: 10px 30px;
  font-size: clamp(14px, 1vw, 18px);
  font-weight: 600;
  background: var(--gradient);
  border: none;
  outline: none;
  border-radius: 50px;
}
.checkCircle {
  width: 26px;
  height: 26px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.leftText {
  font-size: clamp(50px, 6vw, 70px);
  background: linear-gradient(
    142deg,
    rgba(75, 134, 252, 1) 30%,
    rgba(117, 242, 246, 1) 100%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.leftCol {
  background-color: #f1f1ff3d;
  padding: 0;
}
.cards_imgs {
  width: 65px;
  height: 44px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.toStartbn {
  width: 100%;
  padding: 10px;
  background-color: #07111e;
  border: none;
  outline: none;
  color: #fff;
  transition: 250ms;
}
.toStartbn:hover {
  background-color: #07111e9f;
}
.rightCol {
  background-image: url(../../assets/images/Rectangle\ 14419.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px;
}
.rightCol_para {
  background: var(--gradient);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.subSec_second_row {
  margin-top: 100px;
}
.siteToolsImg {
  width: 120px;
  height: 100px;
  object-fit: contain;
  object-position: center;
}
.viewApiText {
  font-size: clamp(28px, 3.5vw, 40px);
}
.apiBtn {
  background: var(--gradient);
  border: 2px solid transparent;
  border-radius: 50px;
}
.apibtn_inner_wrap{
  padding: 10px;
  background: #000;
  transition: 250ms ease-in-out;
  border-radius: 100px;
}
.apibtn_text{
  font-size: 28px;
  background: var(--gradient);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: 250ms ease-in-out;

}
.apiBtn > div > div {
  width: 60px;
  height: 60px;
}
.apiBtn:hover .apibtn_inner_wrap {
  background: var(--gradient);
  color: #000 !important;
}
.apiBtn:hover .apibtn_text {
  background: #000;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.ourclients_wrapper {
  background: linear-gradient(
    360deg,
    hsla(225, 89%, 4%, 1) 0%,
    hsla(208, 73%, 10%, 1) 100%
  );
  padding-top: 100px;
}
.message_wrap {
  background: linear-gradient(
    135deg,
    hsla(0, 0%, 100%, 1) 0%,
    hsla(256, 10%, 22%, 1) 0%,
    hsla(255, 17%, 14%, 1) 26%,
    hsla(258, 25%, 10%, 1) 53%,
    hsla(258, 29%, 9%, 1) 67%
  );
  margin-top: 100px;
}
.topLogo {
  width: 70px;
  height: 70px;
  background-image: url(../../assets/images/Logo.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.profImg {
  width: 58px;
  height: 48px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50px;
}
.profName {
  font-size: 20px;
}
.profName span {
  font-size: 16px;
}
.form_wrapper {
  background: linear-gradient(
    360deg,
    hsla(208, 73%, 10%, 1) 0%,
    hsla(225, 89%, 4%, 1) 100%
  );
  position: relative;
}
.formInput {
  height: 50px;
  /* background: linear-gradient(
    135deg,
    hsla(0, 0%, 100%, 1) 0%,
    hsla(256, 10%, 22%, 1) 0%,
    hsla(255, 17%, 14%, 1) 26%,
    hsla(258, 25%, 10%, 1) 53%,
    hsla(258, 29%, 9%, 1) 67%
  ); */
  background: rgb(17, 16, 29);
  background: linear-gradient(
    356deg,
    rgba(17, 16, 29, 1) 39%,
    rgba(32, 31, 42, 1) 98%
  );
  outline: none;
  border: none;
  padding: 10px 15px;
  color: #fff;
}
.formInput::placeholder {
  color: #fff;
  font-weight: 400;
  font-size: 14px;
}
.formContainer {
  padding-top: 100px;
}
.row {
  border-color: #e5e5e5;
}
.side_globe {
  position: absolute;
  max-width: 352px;
  max-height: 341px;
  width: 20vw;
  height: 20vw;
  background-image: url(../../assets/images/globe.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  top: 0;
  left: 0;
}
@media only screen and (min-width:1400px) {
  .left_wrapper{
    margin-left: 70px;
  }
}

@media only screen and (max-width:767px) {
  .backgroundCircles{
    width: 50vw;
  height: 50vw;
  }
  .our_paymentMethod_wrapper{
    margin-top: 50px;
  }
}