.wrapper {
  background: linear-gradient(
    360deg,
    hsla(225, 89%, 4%, 1) 0%,
    hsla(208, 73%, 10%, 1) 100%
  );
  padding-top: 100px;
}
.siteLogo {
  width: 53px;
  height: 53px;
  background-image: url(../../assets/images/Logo.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.leftfirstCol {
  width: 35%;
}
.text {
  font-size: clamp(14px, 0.8vw, 16px);
  color: #fff;
  font-weight: 400;
}
.list {
  list-style-type: none;
}
.list li {
  font-size: clamp(14px, 0.8vw, 16px);
  color: #fff;
  font-weight: 400;
  cursor: pointer;
}
.socialLogo {
  width: 20px;
  height: 20px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.site_name {
  font-size: clamp(24px, 3vw, 35px);
  background: var(--gradient);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.copyright_text{
    font-size: clamp(14px,1.3vw,18px);
    font-weight: 200 !important;
}
@media only screen and (max-width: 567px) {
  .leftfirstCol {
    width: 100%;
  }
}
