@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

*,*::before,*::after{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
li,
ul,
ol {
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6{
  font-weight: 600;
}

.__container{
  width: 1300px;
  margin: 0 auto;
}


:root {
  --gradient: linear-gradient(
    142deg,
    rgba(75, 134, 252, 1) 30%,
    rgba(117, 242, 246, 1) 100%
  );
}
button{
  border: none;
  outline: none;
}
a{
  text-decoration: none;
}

@media only screen and (max-width: 1300px)  {
  .__container{
    width: 1100px;
  }
}
@media only screen and (max-width: 1100px)  {
  .__container{
    width: 860px;
  }
}
blockquote {
  font-size: clamp(16px, 2vw,21px);
  position: relative;
  color: #fff;
}

blockquote::before {
  content: "\201C";
  display: block;
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 9;
  transform: translate(-50% ,-40%);
  background: rgb(51,187,207);
  background: linear-gradient(180deg, rgba(51,187,207,1) 39%, rgba(222,249,250,1) 100%);
  -webkit-text-fill-color: transparent;

  background-clip: text;
}
blockquote::after {
  content: "\201C";
  display: block;
  font-size: 100px;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotateZ(180deg) translate(-50%,70%);background: rgb(51,187,207);
  background: linear-gradient(180deg, rgba(51,187,207,1) 39%, rgba(222,249,250,1) 100%);
  -webkit-text-fill-color: transparent;

  background-clip: text;
}
body::-webkit-scrollbar {
  width: 5px;
}
 
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
body::-webkit-scrollbar-thumb {
  background-color: #000;
  outline: 1px solid slategrey;
}
