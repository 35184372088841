.siteLogo {
    width: 53px;
    height: 53px;
    background-image: url(../../assets/images/Logo.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .arrowIcon {
    width: 40px;
    height: 40px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(../../assets//icons/arrowUp.png);
    background-size: 50%;
    background-position: center;
    background-color: #fff;
    background-repeat: no-repeat;
  }
  .back_arrow{
    width: 50px;
    height: 50px;
    background-image: url(../../assets/icons/arrow-left.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    
  }