.wrapper {
  width: 100vw;
  height: 100%;
  background-image: url(../../assets/images/background.png);
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
}

.leftCol {
  background-image: url(../../assets/images/Rectangle\ 14419.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.input_label {
  font-size: 14px;
  font-weight: 200;
  color: #2a3547;
  display: block;
}
.input_field {
  border: none;
  outline: none;
  color: #000;
  padding: 8px 10px;
  font-size: 16px;
}
.input_field::placeholder,
.textarea_field::placeholder {
  font-size: 20px;
  color: #333333;
}
.textarea_field {
  border: none;
  border-bottom: 1px solid #b0b0b0;
  outline: none;
  color: #000;
  resize: none;
}
.welcome_text {
  font-size: clamp(24px, 2.5vw, 30px);
  color: #2a3547;
}
.text {
  font-size: 14px;
  font-weight: 200;
  color: #2a3547;
}
.social_login_btn {
  padding: 10px 25px;
  border: 1px solid #eaeff4;
  background-color: white;
  transition: 250ms ease-in-out;
}
.social_login_btn:hover {
  background-color: #00000018;
}
.social_icons {
  width: 24px;
  height: 24px;
  object-fit: cover;
  object-position: center;
}
.divider > div {
  width: 50%;
  height: 2px;
  background-color: #eaeff4;
}
.login_btn {
  width: 100%;
  padding: 10px;
  background-color: #5d87ff;
  color: white;
  font-size: 16px;
  border-radius: 5px;
  transition: 250ms ease-in-out;
}
.login_btn:hover {
  background: #5d88ffb4;
}
@media only screen and (min-width: 1200px) {
  .form_wrapper {
    padding: 0 80px !important;
  }
}
@media only screen and (max-width: 991px) {
  .social_login_btn {
    width: 100%;
  }
}
