.wrapper {
  width: 100vw;
  height: 100%;
  background-image: url(../../assets/images/background.png);
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
}
.back_arrow {
  width: 50px;
  height: 50px;
  background-image: url(../../assets/icons/arrow-left.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center ;
}
.leftCol{
    background-image: url(../../assets/images/Rectangle\ 14419.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center ;
}
.input_label{
    font-size: 12px;
    color: #B0B0B0;
    font-weight: 400;
    display: block;
}
.input_field{
    border: none;
    border-bottom: 1px solid #b0b0b0;
    outline: none;
    color: #000;
}
.input_field::placeholder ,.textarea_field::placeholder{
    font-size: 20px;
    color: #333333;
}
.textarea_field{
    border: none;
    border-bottom: 1px solid #b0b0b0;
    outline: none;
    color: #000;
    resize: none;
}
.startbtn {
    background: var(--gradient);
    border: 2px solid transparent;
    border-radius: 50px;
  }
  
  .btnInside_wrap {
    padding: 8px 10px;
    background: #fff;
    transition: 250ms ease-in-out;
  }
  .btn_text {
    font-size: 20px;
    background: var(--gradient);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: 250ms ease-in-out;
  }
  .circle {
    width: 35px;
    height: 35px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(../../assets//icons/arrowUp.png);
    background-size: 40%;
    background-position: center;
    background-color: rgb(75, 134, 252);
    background-repeat: no-repeat;
  }
  
  .startbtn:hover .btnInside_wrap {
    background: var(--gradient);
    color: #000 !important;
  }
  .startbtn:hover .btn_text {
    background: #000;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .informations{
    font-size: clamp(18px,1vw,23px);
  }
  .contact_para{
    font-size: 12px;
    color: #bcbcbc;
  }
  .list{
    list-style-type: none;

  }
  .icons{
    width: 32px;
    height: 32px;
    object-fit: cover;
    object-position: centerF;
  }
  .list p{
    font-size: clamp(12px ,1.2vw,16px);
    color: white;

  }